import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicForm from "../../stories/Forms/DynamicForm";
import { loginFields } from "../../stories/Forms/fields";
import { loginSchema } from "../../stories/Forms/validationSchema";
import { Button } from "../../stories/Buttons/Button";
import { useAuth } from "../../components/AuthContext/AuthContext";
import { BoxArrowInRight, XSquare } from "react-bootstrap-icons";
import useApis from "../../services/useAPI";
import { loginUrl } from "../../utils/constants";
import { URL_TO_TEST_TYPE, TEST_TYPES } from "../../utils";
import { isMfaRequired } from "./MFAExpiry";
import * as yup from "yup";

let mfa = true;

/**
 * LoginPage Component
 *
 * This component renders the login page for the MyHealthChecked dashboard.
 * It handles user authentication, reCAPTCHA validation, and MFA setup if required.
 *
 * @returns {JSX.Element} The rendered LoginPage component.
 */

const LoginPage = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { callApi, loading, serverError, resultGet, resultPost } = useApis();
  const [recaptchaRequired, setRecaptchaRequired] = useState(false);
  const [loginData, setLoginData] = useState()

  const isPhlebotomist = localStorage.getItem("test_type") ? true : false;

  // Kept commented for further enhancements
  // useEffect(() => {
  //   // Check if MFA is required
  //   if (isMfaRequired()) {
  //     mfa = true;
  //   } else {
  //     mfa = false;
  //   }
  // }, []);

  // Extract test_type from URL
  useEffect(() => {
    const pathSegments = location.search.split("%2F");
    let matchedTestType = null;

    pathSegments.forEach(segment => {
      // Check in URL_TO_TEST_TYPE
      if (URL_TO_TEST_TYPE[segment]) {
        matchedTestType = URL_TO_TEST_TYPE[segment];
      }

      // Check in TEST_TYPES
      if (TEST_TYPES[segment]) {
        matchedTestType = TEST_TYPES[segment];
      }
    });

    if (matchedTestType) {
      localStorage.setItem("test_type", matchedTestType);
    }
  }, [location]);

  // Checks if reCAPTCHA is required
  useEffect(() => {
    const checkReCaptcha = async () => {
      await callApi(null, loginUrl, "GET");
    };

    recaptchaRequired && checkReCaptcha();
  }, []);

  // Handle GET response to check for reCAPTCHA requirement
  useEffect(() => {
    if (resultGet) {
      setRecaptchaRequired(resultGet.recaptcha_required);
    }
  }, [resultGet]);

  // Shows toast message if there's a server error
  useEffect(() => {
    if (serverError) {
      auth?.showToast("Error", serverError, "../assets/mhc.png", <XSquare />);
    }
  }, [serverError]);

  // Handles form submission
  const handleLogin = async values => {

    const encodedPassword = btoa(values.password.trim());
    setLoginData(
      {
        email: values?.email,
        password: values.password
      }
    )
    const payload = {
      email: values.email,
      password: encodedPassword,
      is_phlebotomist: isPhlebotomist,
      ...(recaptchaRequired && { recaptcha_token: values.recaptcha_token })
    };

    await callApi(payload, loginUrl, "POST");
  };
  // Handle POST response after form submission
  useEffect(() => {
    const url = auth.nextURl ? `${auth.nextURl}` : "/";
    if (resultPost?.data) {
      !resultPost?.data?.mfa_enabled && auth.setIsAuthenticated(true);
      !resultPost?.data?.mfa_enabled && localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("access_token", resultPost.data.access_token);
      localStorage.setItem("refresh_token", resultPost.data.refresh_token);
      localStorage.setItem("username", resultPost.data.fullname);
      localStorage.setItem("email", resultPost.data.email);
      localStorage.setItem("role", resultPost.data.role);
      localStorage.setItem("user_id", resultPost.data.id);
      localStorage.setItem("mfa_enabled", resultPost.data.mfa_enabled);
      localStorage.setItem("is_phlebotomist", isPhlebotomist);

      navigate(
        resultPost?.data?.mfa_enabled ? (mfa ? "/confirm-otp" : url) : url
      );

      // Shows success message if user logs in successfully
      !resultPost?.data?.mfa_enabled && auth?.showToast(
        "Logged in",
        "Logged in successfully!",
        "../assets/mhc.png",
        <BoxArrowInRight />
      );
    } else if (serverError || resultPost?.data?.recaptcha_required) {
      setRecaptchaRequired(true);
    }
  }, [resultPost, navigate, auth]);

  const updatedFields = recaptchaRequired
    ? [
      ...loginFields.slice(0, 2),
      {
        controlId: "recaptcha",
        label: "reCAPTCHA",
        type: "recaptcha",
        name: "recaptcha_token",
        required: true
      },
      ...loginFields.slice(2)
    ]
    : loginFields;

  // Passing schema dynamically based on recaptchaRequired
  const updatedSchema = recaptchaRequired
    ? loginSchema.shape({
      email: yup
        .string()
        .required("Email is required")
        .email(),
      password: yup.string().required("Password is required"),
      recaptcha_token: yup.string().required("reCAPTCHA is required")
    })
    : loginSchema;

  return JSON.parse(localStorage.getItem("isLoggedIn")) ? (
    navigate("/")
  ) : (
    <div className="initial-form-wrapper">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="">
            <h1 className="mb-4">MyHealthChecked Dashboard</h1>
            <DynamicForm
              fields={updatedFields}
              validationSchema={updatedSchema}
              initialValues={{ ...loginData }}
              onSubmit={handleLogin}
              isLoading={loading}
              error={serverError}
            />
            <hr />
            <h2>Create account</h2>
            <p className="lead">
              Don't have an account yet? You can create one now.
            </p>
            <div className="text-right">
              <Button
                className="btn-create-account"
                label={"Create account"}
                href="/create-account"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default LoginPage;
